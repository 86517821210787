// app.js
import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { PageLoader } from "./components/page-loader";
import { AuthenticationGuard } from "./components/authentication-guard";
import EventsPage from "./pages/events-page";
import { CallbackPage } from "./pages/callback-page";
// import { WaitlistHomePage } from "./pages/waitlist-home-page";
import { LaunchPrizePage } from "./pages/launchPrize-page";
import  BigGame  from "./pages/bigGame-page";
import { NotFoundPage } from "./pages/not-found-page";
import { ProfilePageTest } from "./pages/profile-page-test";
import { StartPg } from "./pages/start-page";
import ListingsPageTest from "./pages/listings-page-test";
import {PublicProfilePage} from "./pages/public-temp";
import EventPage from "./pages/individual-event-page";
import TicketPage from "./pages/individual-ticket-page";
import { ChatPage } from "./pages/chat-page";
import DeleteAccountPage from "./pages/deleteAccountPage";
import { ListingForm } from "./pages/sale-form-test";
import TermsOfUse from "./pages/terms-of-use";
import PrivacyPolicy from "./pages/privacy-policy";
import AutoLogin from "./server/routes/autoLoginRoute";
import AutoSignup from "./server/routes/autoSignupRoute";
import { EmailVerify } from "./pages/email-verify-page";
import LandingPage from "./pages/landing-page";
import { LoaderTest } from "./pages/loader-test";
import { SupportPg } from "./pages/support-page";
import { PassportPage } from "./pages/passport-page"
import posthog from "posthog-js";
import ProfileTokenPage from "./pages/profileTokenPage";

export const App = () => {
  const { isLoading } = useAuth0();
  const location = useLocation();

  // Add PostHog pageview tracking
  useEffect(() => {
    posthog.capture('$pageview');
  }, [location]);

  if (isLoading) {
    return (
      <div className="page-layout">
        <PageLoader />
      </div>
    );
  }

  return (
    <Routes>
      {/* start pg = home page ⬇️ */}
      <Route path="/" element={<StartPg />} />
      <Route path="/landing" element={<LandingPage />} />
      <Route path="/loader" element={<LoaderTest />} />
      {/* Auto routes for auth.campus-ticket.com */}
      <Route path="/join" element={<AutoSignup />} />
      <Route path="/signup" element={<AutoSignup />} />
      <Route path="/login" element={<AutoLogin />} />

      <Route path="/start" element={<StartPg />} />
      <Route path="/support" element={<SupportPg />} />
      <Route
        path="/profile"
        element={<AuthenticationGuard component={ProfilePageTest} />}
      />
      {/* <Route
        path="/myprofile/:instagramHandle"
        element={<AuthenticationGuard component={ProfilePageTest} />}
      /> */}
      <Route path="/events" element={<EventsPage />} />
      <Route path="/listings" element={<ListingsPageTest />} />
      <Route path="/profile/:instagramHandle" element={<PublicProfilePage />}/>
      <Route path="/events/:region/:eventName" element={<EventPage />}/>
      <Route path="/goldenbears" element={<LaunchPrizePage />}/>
      <Route path="/applanding" element={<LandingPage />} />
      <Route path="/biggame" element={<BigGame />} />
      <Route path="/tickets/:ticketId" element={<TicketPage />} />
      <Route
        path="/profile/:tokenStatus/:tokenType/:userId/:instagramHandle"
        element={<ProfileTokenPage />}
      />
      <Route
        path="/myprofile"
        element={<ProfileTokenPage />}
      />
      <Route
        path="/passport"
        element={<AuthenticationGuard component={PassportPage} />}
      />
      <Route
        path="/sell"
        element={<AuthenticationGuard component={ListingForm} />}
      />
      <Route
        path="/chat"
        element={<AuthenticationGuard component={ChatPage} />}
      />
      <Route
        path="/goodbye"
        element={<AuthenticationGuard component={DeleteAccountPage} />}
      />
      <Route path="/callback" element={<CallbackPage />} />
      <Route path="*" element={<NotFoundPage />} />
      <Route path="/verify" element={<EmailVerify/>} />
      <Route path="/terms" element={<TermsOfUse />} />
      <Route path="/privacy" element={<PrivacyPolicy />} />
    </Routes>
  );
};
