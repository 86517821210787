import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import posthog from "posthog-js";
import { PostHogProvider } from 'posthog-js/react';
import { App } from "./app.js";
import { Auth0ProviderWithNavigate } from "./context/auth0-provider-with-navigate.js";
import { SocketContextProvider } from "./context/socket-provider.js";
import { NextUIProvider } from "@nextui-org/react";
import { ProfanityFilterProvider } from './context/ProfanityFilterContext.js';
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from '@vercel/speed-insights/react';
import PostHogPageviewTracker from "./analytics/PostHogPageviewTracker";
import "./styles/styles.css";
import "./styles/tailwind.css";

const container = document.getElementById("root");
const root = createRoot(container);

posthog.init(process.env.REACT_APP_PUBLIC_POSTHOG_KEY, {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  capture_pageview: false,
});

const posthogOptions = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
}

if (process.env.NODE_ENV === "production") {
  console.log = () => {}; // Disabling all console.log in production
}

root.render(
  <>
    <React.StrictMode>
      <PostHogProvider 
        apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
        client={posthog}
        options={posthogOptions}
      >
        <BrowserRouter>
          <Auth0ProviderWithNavigate>
            <SocketContextProvider>
              <NextUIProvider>
                <ProfanityFilterProvider> 
                  <App />
                </ProfanityFilterProvider> 
                <Analytics />
                <SpeedInsights />
              </NextUIProvider>
            </SocketContextProvider>
            </Auth0ProviderWithNavigate>
        </BrowserRouter>
      </PostHogProvider>
    </React.StrictMode>
  </>
);

// @import "theme.css";
// @import "general.css";
// @import "components/index.css";
