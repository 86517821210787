// EventCountdown.js
import React, { useState, useEffect } from "react";
import styles from "../../styles/pages/events-page.module.css"; // Update with correct path

// Helper function to calculate the time difference in hours and days
const calculateTimeDifferenceInHours = (eventDateTime) => {
  const now = Date.now(); // Get the current time in milliseconds
  const eventDate = Date.parse(eventDateTime); // Parse the dateTime string to get milliseconds

  // Handle invalid date parsing
  if (isNaN(eventDate)) {
    console.error("Invalid date format:", eventDateTime);
    return { diffInHours: NaN, diffInDays: NaN };
  }

  const diffInMilliseconds = eventDate - now;
  const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));
  const diffInHours = Math.floor(diffInMinutes / 60);
  const diffInDays = Math.floor(diffInHours / 24);
  const diffInWeeks = Math.floor(diffInDays / 7);
  const remainingDays = diffInDays % 7;
  const remainingHours = diffInHours % 24;

  return { diffInHours, diffInDays, diffInWeeks, remainingDays, remainingHours };
};

// Function to determine the label based on the time difference
const getTimeLabel = (timeDifference) => {
  const { diffInHours, diffInDays, diffInWeeks, remainingDays, remainingHours } = timeDifference;

  if (isNaN(diffInHours)) {
    return <div>Error: Invalid date</div>;
  }

  if (diffInHours < 0) {
    return <div>Event has passed</div>;
  }

  // Less than 24 hours
  if (diffInHours < 6) {
    return <div className={styles.timeLabelPurple}>{diffInHours} hrs left</div>;
  } else if (diffInHours < 12) {
    return <div className={styles.timeLabelRed}>{diffInHours} hrs left</div>;
  } else if (diffInHours < 24) {
    return <div className={styles.timeLabelYellow}>{diffInHours} hrs left</div>;
  }
  
  // Between 1 day and 1 week
  else if (diffInDays < 2) {
    return <div className={styles.timeLabelBlue}>{diffInDays} day {remainingHours} hrs left</div>;
  }

  else if (diffInDays < 7) {
    return <div className={styles.timeLabelBlue}>{diffInDays} days {remainingHours} hrs left</div>;
  }
  
  // Between 1 week and 1 month
  else if (diffInDays < 30) {
    return <div className={styles.timeLabelGreen}>
      {diffInWeeks} {diffInWeeks === 1 ? 'week' : 'weeks'} {remainingDays} {remainingDays === 1 ? 'day' : 'days'} left
    </div>;
  }
  
  // More than a month
  else {
    return <div className={styles.timeLabelGrey}>More than 1 month away</div>;
  }
};

export const EventCountdown = ({ dateTime }) => {
  const [timeDifference, setTimeDifference] = useState(null);

  useEffect(() => {
    const timeDiff = calculateTimeDifferenceInHours(dateTime);
    setTimeDifference(timeDiff);
  }, [dateTime]);

  return timeDifference ? getTimeLabel(timeDifference) : <p>Loading...</p>;
};
