import React from 'react';
import styles from '../styles/components/notification-badge.module.css';

export const NotificationBadge = ({ count, showRequests }) => {
  if (!count) return null;
  
  const badgeColor = showRequests ? styles.blue : styles.red;

  return (
    <div className={`${styles.badge} ${badgeColor}`}>
      {count > 99 ? '!' : count}
    </div>
  );
}; 

export default NotificationBadge;