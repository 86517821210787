import React from "react";
import { useParams } from "react-router-dom";
import { ProfilePageTest } from "./profile-page-test";

const ProfileTokenPage = () => {
  const { tokenStatus, tokenType, userId, instagramHandle } = useParams();

  return (
    <div>
      <p>Token Status: {tokenStatus}</p>
      <p>Token Type: {tokenType}</p>
      <p>User ID: {userId}</p>
      <p>Instagram Handle: {instagramHandle}</p>
      {/* Add logic or navigation here as needed */}
      <ProfilePageTest/>
    </div>
  );
};

export default ProfileTokenPage;
