import React, { useState } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import styles from '../../styles/components/newChatModal.module.css';

const NewChatModal = ({ onClose, acceptedChats, pendingSentChats, chatInvites }) => {
  const [error, setError] = useState(null);
  const [chatName, setChatName] = useState('');
  const [invited_members_ig, setInvitedUserIg] = useState('');
  const { user } = useAuth0();
  const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080';


  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!chatName || !invited_members_ig) {
      const errorMessage = 'Chat name and user to invite are required';
      setError(errorMessage);
      alert(errorMessage); 
      return;
    }

    // Trim surrounding spaces
    const trimmedIgHandle = invited_members_ig.trim();

    // check for valid Instagram handle
    const validIgHandleRegex = /^[a-zA-Z0-9._]+$/;

    // Check if the handle is empty after trimming
    if (!chatName || !trimmedIgHandle) {
      const errorMessage = 'Chat name and user to invite are required';
      setError(errorMessage);
      alert(errorMessage);
      return;
    }

    // Check for punctuation at the start/end or spaces in the middle of the handle
    if (!validIgHandleRegex.test(trimmedIgHandle) || /\s/.test(trimmedIgHandle)) {
      const errorMessage = 'Invalid Instagram handle. No punctuation at the start/end, no spaces allowed.';
      setError(errorMessage);
      alert(errorMessage);
      return;
    }

    if (user.instagram_handle === invited_members_ig) {
      const errorMessage = 'Cannot invite yourself';
      setError(errorMessage);
      alert(errorMessage); 
      return;
    }

    // Check if this handle already has an accepted chat
    if (acceptedChats.some(chat => chat.accepted_members_ig === trimmedIgHandle)) {
      const errorMessage = 'You already have a chat with this user.';
      setError(errorMessage);
      alert(errorMessage);
      return;
    }

    // Check if this handle already has a pending invite sent by the current user
    if (pendingSentChats.some(chat => chat.invited_members_ig === trimmedIgHandle)) {
      const errorMessage = 'You have already sent a request to this user.';
      setError(errorMessage);
      alert(errorMessage);
      return;
    }

    // Check if this handle already invited the current user
    if (chatInvites.some(chat => chat.creatorIg === trimmedIgHandle)) {
      const errorMessage = 'You have received a chat invite from this user already.';
      setError(errorMessage);
      alert(errorMessage);
      return;
    }
  
    const newChatData = {
      chatName,
      creatorIg: String(user.instagram_handle),
      creatorId: String(user.sub), 
      invited_members_ig,
      historyBackup: Date.now()
    };
  
    try {
      const response = await axios.post(`${REACT_APP_API_BASE_URL}/api/chats`, newChatData);
      if (response.status === 201) {
        alert('User invited!');
        onClose();
      } else {
        alert('Failed to create chat.');
      }
    } catch (error) {
      console.error('Error creating chat:', error);
    }
  };  

  return (
    <div className={styles.modal}>
      <div className={styles.modalContent}>
        <h2>Create New Chat</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Chat Name"
            value={chatName}
            onChange={(e) => setChatName(e.target.value)}
            required
            className={styles.input}
          />
          <input
            type="text"
            placeholder="Enter user instagram handle (without @)"
            value={invited_members_ig}
            onChange={(e) => setInvitedUserIg(e.target.value)}
            required
            className={styles.input}
          />
          <div className={styles.modalButtons}>
          <button className={styles.inviteButton} type="submit">Invite User</button>
          <button className={styles.cancelButton} type="button" onClick={onClose}>Cancel</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewChatModal;
