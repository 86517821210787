import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import axios from 'axios';
import styles from '../styles/pages/artist-page.module.css';
import { PageLayout } from "../components/page-layout";
import { SaleCard } from "../components/sale-listings/sale-listing";
import { SwapCard } from "../components/swap-listings/swap-listing";
import { DatedEventCard } from "../components/event-cards/datedEventCard";
import DropdownButton from "../components/buttons/dropdown-button";
import NewMsgModal from "../components/modals/newMsgModal";

const EventPage = () => {
  const { region, eventName } = useParams();
  const { user } = useAuth0();
  const [events, setEvents] = useState([]);
  const [listings, setListings] = useState([]);
  const [swaps, setSwaps] = useState([]);
  const [lineups, setLineups] = useState([]);
  const [activeButton, setActiveButton] = useState('listings');
  const [activeDropdownButton, setActiveDropdownButton] = useState('sale');
  const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080';
  const REACT_APP_FRONTEND_BASE_URL = process.env.REACT_APP_FRONTEND_BASE_URL || 'http://localhost:3000';
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 600); // Initialize state
  const [currentItem, setCurrentItem] = useState(null);
  const [isNewMsgModalOpen, setIsNewMsgModalOpen] = useState(false);
  const [blockedUsers, setBlockedUsers] = useState([]);

  useEffect(() => {
    const fetchBlockedUsers = async () => {
      try {
        if (user) {
          // Fetch blocked users
          const response = await axios.get(
            `${REACT_APP_API_BASE_URL}/api/getUserMetadata/${user.sub}`
          );
          const blockedUsersData = response.data.metadata.blocked_users || [];
          setBlockedUsers(blockedUsersData);
          console.log("blockedUsers:", blockedUsersData);

          // Fetch ticket details after blocked users are set
          fetchEventsAndListings(blockedUsers);
          fetchSwaps(blockedUsers);
        } else if (!user) {
          fetchEventsAndListings([]);
          fetchSwaps([]);
        }
      } catch (error) {
        console.error("Error fetching blocked users:", error);
      }
    };

    fetchBlockedUsers();
  }, [user, REACT_APP_API_BASE_URL]);

    const fetchEventsAndListings = async (blockedUsers) => {
      if (!region || !eventName) {
        console.error('Region or event name missing');
        console.log('No event or region name provided');
        return;
      }
  
      try {
        console.log(`Fetching data for region: ${region} and artist: ${eventName}`);
        const response = await axios.get(`${REACT_APP_API_BASE_URL}/api/events/${encodeURIComponent(region)}/${encodeURIComponent(eventName)}`);
  
        let { events = [], listings = [] } = response.data;
  
        // Filter events
        events = events.filter(event => 
          event.artistName.includes(eventName) || event.eventName.includes(eventName)
        );

        setEvents(events);

        // Filter listings if seller is blocked
        if (listings.length !== 0 && blockedUsers) {
          const notFromBlockedUsersListings = listings.filter(
            (listing) =>
              !blockedUsers.includes(listing.seller_id)
          );
          setListings(notFromBlockedUsersListings);
        } else {
          setListings(listings);
        }
      } catch (error) {
        console.error('Error fetching event and listing data:', error);
      }
    };
  
    const fetchSwaps = async (blockedUsers) => {
      if (!region || !eventName) {
        console.error('Region or event name missing');
        return;
      }
    
      try {
        console.log(`Fetching swaps for event: ${eventName}`);
        const response = await axios.get(`${REACT_APP_API_BASE_URL}/api/swaps/${region}/${encodeURIComponent(eventName)}`);
    
        let swaps = response.data || [];
    
        // Filter swaps based on blocked users
        if (blockedUsers && swaps) {
          const notFromBlockedUsersSwaps = swaps.filter(
            (swap) => !blockedUsers.includes(swap.swapper_id)
          );
          setSwaps(notFromBlockedUsersSwaps);
        } else {
          setSwaps(swaps);
        }
      } catch (error) {
        console.error('Error fetching swaps:', error);
      }
    };
    
  
  const fetchLineups = async () => {
    try {
      const response = await axios.get(`${REACT_APP_API_BASE_URL}/api/lineups/concert`, {
        params: { concertName: eventName }
      });
      setLineups(response.data || []);
    } catch (error) {
      console.error('Error fetching lineups:', error);
    }
  };

  const handleVisitUserProfilePg = (instagramHandle) => {
    const profileLink = `${REACT_APP_FRONTEND_BASE_URL}/profile/${instagramHandle}`;
    window.open(profileLink, '_blank');
    // @shiv to open in same tab use window.location.href

  };

  const handleToggle = (button) => {
    setActiveButton(button);
    if (button === 'usersAttending') {
      fetchLineups();
    }
  };

  const singleEvent = events[0];

  const getActiveDropdownButtonLabel = () => {
    switch (activeDropdownButton) {
      case "sale":
        return listings.length > 0 ? `Sale Listings (${listings.length})` : "Sale Listings";
      case "swaps":
        return swaps.length > 0 ? `Swaps (${swaps.length})` : "Swaps";
      default:
        return "...";
    }
  };

  const handleConnectClick = (item) => {
    setCurrentItem(item);
    setIsNewMsgModalOpen(true);
  };

  const handleCloseNewMsgModal = () => {
    setIsNewMsgModalOpen(false);
    setCurrentItem(null);
  };  

  
  return (
    <PageLayout>
      <div className={styles.artistPage}>
        <h2 className={styles.artistName}>{eventName}</h2>
        {/* Conditionally render halfContainer based on isMobileView */}
        {!isMobileView && (
          // LHS
          <div className={styles.halfContainer}>
            <div>
              <div className={styles.gridContainer}>
                {events.length === 0 ? (
                  <h2>No events found.</h2>
                ) : (
                  singleEvent && (
                    <DatedEventCard key={singleEvent._id} event={singleEvent} />
                  )
                )}
              </div>
            </div>
            {/* RHS */}
            <div className={styles.halfContainerRight}>
              <div className={styles.lineContainer}>
                <div className={styles.toggleButtons}>
                  <button
                    className={activeButton === 'listings' ? styles.activeButton : ''}
                    onClick={() => handleToggle('listings')}
                  >
                    Listings
                  </button>
                  <button
                    className={activeButton === 'usersAttending' ? styles.activeButton : ''}
                    onClick={() => handleToggle('usersAttending')}
                  >
                    Users Attending
                  </button>
                </div>
              </div>
              {activeButton === 'listings' ? (
              <div className={styles.lineContainer}>
                {activeButton === 'listings' && (
                  <DropdownButton label={getActiveDropdownButtonLabel()}>
                      <button onClick={() => {
                          setActiveDropdownButton("sale");

                      }}>Sale {listings.length > 0 && (` (${listings.length})`)}</button>
                      <button onClick={() => {
                          setActiveDropdownButton("swaps");
                      }}>Swaps {swaps.length > 0 && (` (${swaps.length})`)}</button>
                  </DropdownButton>
                )}
                </div>
                ) : ( null )}
              <div className={styles.gridContainer}>
                {activeButton === 'listings' ? (
                    <>
                      {activeDropdownButton === 'sale' && (
                        <>
                          {listings.length === 0 ? (
                            <div className={styles.eventCard}>
                              <h2>No sale listings found for this event.</h2>
                              <p>
                              Discover other listings{' '}
                                <a href="/listings" target="_blank" rel="noopener noreferrer" className={styles.aLink}>
                                  here
                                </a>
                                !
                              </p>
                            </div>
                          ) : (
                            listings.map((listing) => (
                              <SaleCard key={listing._id} listing={listing} handleConnect={handleConnectClick}/>
                            ))
                          )}
                        </>
                      )}
                      
                      {activeDropdownButton === 'swaps' && (
                        <>
                          {swaps.length === 0 ? (
                            <div className={styles.eventCard}>
                              <h2>No ticketswaps found for this event.</h2>
                              <p>
                              Discover other listings{' '}
                                <a href="/listings" target="_blank" rel="noopener noreferrer" className={styles.aLink}>
                                  here
                                </a>
                                !
                              </p>
                            </div>
                          ) : (
                            swaps.map((swap) => (
                              <SwapCard key={swap._id} swap={swap} handleConnect={handleConnectClick}/>
                            ))
                          )}
                        </>
                      )}
                    </>
                  ) : (
                  lineups.length === 0 ? (
                    <div className={styles.eventCard}>
                      <h2>No declared attendees found.</h2>
                      <p>Sharing your event/concert lineup with others <a href="/profile" target="_blank" rel="noopener noreferrer" className={styles.aLink}> here</a>!</p>
                    </div>
                  ) : (
                    lineups.map(lineup => (
                      <div key={lineup._id} className={styles.eventCard}>
                        <div>
                          <p><a href="#" onClick={() => handleVisitUserProfilePg(lineup.userIg)}>{'@' + lineup.userIg}</a></p>
                          <h3>{lineup.concertName}</h3>
                        </div>
                      </div>
                    ))
                  )
                )}
              </div>
            </div>
          </div>
        )}
        {/* Mobile view adjustment */}
        {isMobileView && (
          <div>
            <div>
              <div className={styles.gridContainer}>
                {events.length === 0 ? (
                  <h2>No events found.</h2>
                ) : (
                  singleEvent && (
                    <DatedEventCard key={singleEvent._id} event={singleEvent} />
                  )
                )}
              </div>
            </div>
            <div className={styles.lineContainer}>
              <div className={styles.toggleButtons}>
                <button
                  className={activeButton === 'listings' ? styles.activeButton : ''}
                  onClick={() => handleToggle('listings')}
                >
                  Listings
                </button>
                <button
                  className={activeButton === 'usersAttending' ? styles.activeButton : ''}
                  onClick={() => handleToggle('usersAttending')}
                >
                  Users Attending
                </button>
              </div>
            </div>
              {activeButton === 'listings' ? (
              <div className={styles.lineContainer}>
                {activeButton === 'listings' && (
                  <DropdownButton label={getActiveDropdownButtonLabel()}>
                      <button onClick={() => {
                          setActiveDropdownButton("sale");
                      }}>Sale</button>
                      <button onClick={() => {
                          setActiveDropdownButton("swaps");
                      }}>Swaps</button>
                  </DropdownButton>
                )}
                </div>
                ) : ( null )}
              <div className={styles.gridContainer}>
                {activeButton === 'listings' ? (
                    <>
                      {activeDropdownButton === 'sale' && (
                        <>
                          {listings.length === 0 ? (
                            <div className={styles.eventCard}>
                              <h2>No sale listings found for this event.</h2>
                              <p>
                              Discover other listings{' '}
                                <a href="/listings" target="_blank" rel="noopener noreferrer" className={styles.aLink}>
                                  here
                                </a>
                                !
                              </p>
                            </div>
                          ) : (
                            listings.map((listing) => (
                              <SaleCard key={listing._id} listing={listing} handleConnect={handleConnectClick}/>
                            ))
                          )}
                        </>
                      )}
                      
                      {activeDropdownButton === 'swaps' && (
                        <>
                          {swaps.length === 0 ? (
                            <div className={styles.eventCard}>
                              <h2>No ticketswaps found for this event.</h2>
                              <p>
                              Discover other listings{' '}
                                <a href="/listings" target="_blank" rel="noopener noreferrer" className={styles.aLink}>
                                  here
                                </a>
                                !
                              </p>
                            </div>
                          ) : (
                            swaps.map((swap) => (
                              <SwapCard key={swap._id} swap={swap} handleConnect={handleConnectClick}/>
                            ))
                          )}
                        </>
                      )}
                    </>
                  ) : (
                  lineups.length === 0 ? (
                    <div className={styles.eventCard}>
                      <h2>No declared attendees found.</h2>
                      <p>Sharing your event/concert lineup with others <a href="/profile" target="_blank" rel="noopener noreferrer" className={styles.aLink}> here</a>!</p>
                    </div>
                  ) : (
                    lineups.map(lineup => (
                      <div key={lineup._id} className={styles.eventCard}>
                        <div>
                          <p><a href="#" onClick={() => handleVisitUserProfilePg(lineup.userIg)}>{'@' + lineup.userIg}</a></p>
                          <h3>{lineup.concertName}</h3>
                        </div>
                      </div>
                    ))
                  )
                )}
            </div>
          </div>
        )}
      </div>
      {isNewMsgModalOpen && currentItem && (
        <NewMsgModal
          item={currentItem}
          isSaleListing={activeButton === "listings" && activeDropdownButton === "sale"}
          onClose={handleCloseNewMsgModal}
        />
      )}
    </PageLayout>
  );
};

export default EventPage;