import React, { useState, useEffect } from 'react';
import styles from '../styles/components/unread-badge.module.css';

export const UnreadBadge = ({ chat, user, unreadStatus }) => {
  const [showUnreadIcon, setShowUnreadIcon] = useState(false);

  useEffect(() => {
    if (unreadStatus[chat._id] === false) {
      setShowUnreadIcon(false);  // If the chat is selected and its badge is hidden
    } else {
      if (chat && user) {
        setShowUnreadIcon(
          (user.sub === chat.creatorId && chat.unreadByCreator) ||
          (user.sub !== chat.creatorId && chat.unreadByInvitedUser)
        );
      }
    }
  }, [chat, user, unreadStatus]);

  if (!chat || !user) return null;

  return (
    showUnreadIcon && <div className={`${styles.badge}`} />
  );
};

export default UnreadBadge;
