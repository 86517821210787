import React, { useState, useEffect } from "react";
import { PrizeForm } from "../components/prize-form.js";
import styles from "../styles/pages/prize-home-page.module.css";
import { ReactComponent as TicketIcon } from "../styles/img/Final-Ticket-Small.svg";
import { Accordion, AccordionItem } from "@nextui-org/react";
import { SignupButton } from "../components/buttons/signup-button";
import { useAuth0 } from "@auth0/auth0-react";

export const LaunchPrizePage = () => {
  const { user } = useAuth0();
  const [step, setStep] = useState(0); // Default step is 0
  const [isRedeemButtonEnabled, setIsRedeemButtonEnabled] = useState(false);

  useEffect(() => {
    const startDate = new Date("2024-11-15T11:00:00-08:00"); // 11 AM PST on Nov 15, 2024
    const endDate = new Date("2024-11-16T19:00:00-08:00"); // 7 PM PST on Nov 16, 2024

    const now = new Date();
    setIsRedeemButtonEnabled(now >= startDate && now <= endDate);
  }, []);

  return (
    <div className={styles.prize_page}>
      {step === 0 && (
        <>
          <div>
            <TicketIcon
              className={styles.desktopTicketLogo}
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="xMidYMid meet"
              aria-label="campusticket logo"
            />
          </div>
          <p className={styles.title_text}>
            campus<span style={{ color: "var(--main-yellow)" }}>ticket </span>
          </p>
          <p className={styles.subtitle_text}>
            Buy and Sell concert
            <br />
            tickets with <span>0 fees</span>
          </p>
          <div className={styles.growing_spacer}></div>
          <p className={styles.main_text}>
            This Friday, Nov. 15
            <br />
            200 golden 🎫s on campus
          </p>
          <p className={styles.additional_text}>
            The more you collect the more $ we venmo you!
          </p>

          <div className={styles.qandA}>
            <Accordion>
              <AccordionItem
                key="1"
                aria-label="IMPORTANT!!"
                title="IMPORTANT!!"
                className={styles.blueAccordionTitle}
              >
                <div className={styles.dropdownContent}>
                  <h3>
                    If you submitted codes before 11/15 11 am, we appreciate you
                    and apologize for the following inconvenience. Please
                    resubmit the codes you used as they should still be
                    unredeemed.
                  </h3>
                </div>
              </AccordionItem>
              <AccordionItem
                key="2"
                aria-label="Payouts $$$"
                title="Payouts $$$"
                className={styles.accordionTitle}
              >
                <div className={styles.dropdownContent}>
                  <div className={styles.payoutGrid}>
                    <div>
                      1 ticket - $1
                      <br />
                      2 tickets - $3
                      <br />
                      3 tickets - $6
                      <br />
                      4 tickets - $10
                      <br />
                      5 tickets - $14
                      <br />
                      6 tickets - $18
                      <br />
                      7 tickets - $22
                      <br />
                      8 tickets - $27
                      <br />
                      9 tickets - $35
                      <br />
                      10 tickets - $45
                    </div>
                    <div>
                      11 tickets - $55
                      <br />
                      12 tickets - $70
                      <br />
                      13 tickets - $85
                      <br />
                      14 tickets - $100
                      <br />
                      15 tickets - $115
                      <br />
                      16 tickets - $130
                      <br />
                      17 tickets - $150
                      <br />
                      18 tickets - $170
                      <br />
                      19 tickets - $200
                      <br />
                      20 tickets - $250
                    </div>
                  </div>
                </div>
              </AccordionItem>
              <AccordionItem
                key="3"
                aria-label="Ticket Hunt Rules"
                title="Ticket Hunt Rules"
                className={styles.accordionTitle}
              >
                <div className={styles.dropdownContent}>
                  <h3>
                    Friday Nov 15. campusticket will be hiding 200{" "}
                    <span style={{ color: "var(--main-yellow)" }}>
                      golden tickets
                    </span>{" "}
                    on UC Berkeley campus for students to find and redeem.
                    <br />
                    <br />
                    All tickets are the same and redeemable through this link
                    and on the profile page when signed into the CampusTicket
                    website. Tickets can only be redeemed once and the maximum
                    number of tickets a user can redeem is 20.
                    <br />
                    <br />
                    All Tickets expire by Saturday Nov 16 at 7pm PST{" "}
                  </h3>
                </div>
              </AccordionItem>
            </Accordion>
          </div>
          {!user && (
            <button
              onClick={() =>
                window.open("https://www.instagram.com/campusticket/", "_blank")
              }
              className={styles.redeem_button}
            >
              Event has ended, follow our instagram for future events!
            </button>
          )}
        </>
      )}
      {user && step === 0 && (
        <button
          onClick={() =>
            isRedeemButtonEnabled
              ? setStep(1)
              : window.open("https://www.instagram.com/campusticket/", "_blank")
          }
          className={styles.redeem_button}
        >
          {isRedeemButtonEnabled
            ? "Redeem NOW until 8pm PST 11/16!!"
            : "Event has ended, follow our instagram for future events!"}
        </button>
      )}
      {user && <PrizeForm step={step} setStep={setStep} />}
    </div>
  );
};
