const schoolNames = {
  berkeley: { name: "Berkeley 🐻", region: "bay_area", alt_name: "UC Berkeley" },
  stanford: { name: "Stanford 🌲", region: "bay_area", alt_name: "Stanford" },
  usfca: { name: "USF 🌉", region: "bay_area", alt_name: "USF" },
  sfsu: { name: "SFSU 💜💛", region: "bay_area", alt_name: "SF State" },
  sjsu: { name: "SJSU 💛💙", region: "bay_area", alt_name: "San Jose State" },
  ucla: { name: "UCLA 🧸", region: "los_angeles", alt_name: "UCLA" },
  usc: { name: "USC ✌️", region: "los_angeles", alt_name: "USC" },
  lmu: { name: "LMU 🦁", region: "los_angeles", alt_name: "Loyola" },
  pepperdine: { name: "Pepperdine 🌊", region: "los_angeles", alt_name: "Pepperdine" },
  calstatela: { name: "Cal State LA 🖤💛", region: "los_angeles", alt_name: "CSULA" },
  csulb: { name: "CSULB 🦈", region: "los_angeles", alt_name: "CSULB" },
  smu: { name: "SMU 🐎", region: "dfw", alt_name: "SMU" },
  uta: { name: "UT Arlington 💙🧡", region: "dfw", alt_name: "UT Arlington" },
  utdallas: { name: "UTD ☄️", region: "dfw", alt_name: "UT Dallas" },
  tcu: { name: "TCU 💜🤍", region: "dfw", alt_name: "TCU" },
  dbu: { name: "DBU 💙❤️", region: "dfw", alt_name: "DBU" },
  baylor: { name: "Baylor 💛💚", region: "dfw", alt_name: "Baylor" },
  unt: { name: "UNT 🦅", region: "dfw", alt_name: "UNT" },
  campusticket: {
    name: "Campus Ticket 🎟️",
    region: "bay_area, los_angeles, dfw",
  },
};

export default schoolNames;
