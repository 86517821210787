import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from "../styles/pages/big-game-page.module.css";
import { ReactComponent as TicketIcon } from "../styles/img/Final-Ticket-Small.svg";
import { Accordion, AccordionItem } from "@nextui-org/react";

const BigGame = () => {
  const REACT_APP_FRONTEND_BASE_URL =
  process.env.REACT_APP_FRONTEND_BASE_URL || "http://localhost:3000";
  
  const navigate = useNavigate();

  const handleSellClick = () => {
    const formData = { event_name: "127th Big Game" };
    navigate('/sell', { state: formData });
  };

  return (
    <div className={styles.game_page}>
      <div>
        <TicketIcon
          className={styles.desktopTicketLogo}
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="xMidYMid meet"
          aria-label="campusticket logo"
        />
      </div>
      
      <p className={styles.title_text}>
        The Big Game <span>2024</span>
      </p>
      
      <p className={styles.subtitle_text}>
        <span style={{ color: '#b2040e' }}>Stanford 🌲</span>
        {' 🪓 '}
        <span style={{ color: '#0166ff' }}>Cal 🐻</span>
      </p>

      <div className={styles.growing_spacer}></div>

      <p className={styles.main_text}>
        Buy or Sell tickets on campus<span style={{ color: 'var(--main-yellow)' }}>ticket!</span>
      </p>


      <div className={styles.qandA}>
        <Accordion>
          <AccordionItem
            key="1"
            aria-label="Event Details"
            title="Event Details"
            className={styles.blueAccordionTitle}
          >
            <div className={styles.dropdownContent}>
              <h3>California Memorial Stadium
                <br />
                Berkeley, CA
                <br />
                November 23, 2024
                <br />
                12:00 PM PT
              </h3>
            </div>
          </AccordionItem>
        </Accordion>
      </div>

      <div className={styles.buySellButtonsContainer}>
        <div className={`${styles.gradientWrapper} ${styles.redGradient}`}>
          <button 
            onClick={handleSellClick} 
            className={styles.actionButton}
          >
            Sell tickets
          </button>
        </div>
        <div className={`${styles.gradientWrapper} ${styles.blueGradient}`}>
          <button 
            onClick={() => {
              window.location.href = `${REACT_APP_FRONTEND_BASE_URL}/events/bay_area/127th%20Big%20Game`;
            }}            
            className={styles.actionButton}
          >
            Buy tickets
          </button>
        </div>
      </div>
    </div>
  );
};

export default BigGame;
