import React, { useState } from 'react';
import { Switch } from "@nextui-org/react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from 'axios';
import styles from '../../styles/components/transaction-feedback-modal.module.css';

const TransactionFeedbackModal = ({ onClose, listing }) => {
  const [soldOnPlatform, setSoldOnPlatform] = useState(true);
  const [feedback, setFeedback] = useState('');
  const [error, setError] = useState(null);
  const { user } = useAuth0();
  const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080';

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const feedbackData = {
        listingId: listing._id,
        sellerId: user.sub,
        sellerUsername: user.nickname,
        soldOnPlatform,
        feedback,
        eventName: listing.event_name,
        ticketPrice: listing.price
      };
      console.log("feedbackData", feedbackData);

      await axios.post(
        `${REACT_APP_API_BASE_URL}/api/feedback/new-feedback`,
        feedbackData
      );

      onClose();
    } catch (err) {
      console.error('Error submitting feedback:', err);
      setError('Failed to submit feedback. Please try again.');
    }
  };

  return (
    <div className={styles.modal}>
      <div className={styles.modalContent}>
        <h2>Transaction Feedback</h2>
        <form onSubmit={handleSubmit}>
          <p>Help us improve! Let us know about your selling experience.</p>
          
          <div className={styles.formGroup}>
            <div className={styles.toggleWrapper}>
              <Switch
                defaultSelected={soldOnPlatform}
                onChange={(isSelected) => setSoldOnPlatform(isSelected)}
                size="lg"
                color={soldOnPlatform ? "warning" : "default"}
                classNames={{
                  wrapper: styles.switchWrapper
                }}
              >
                {soldOnPlatform ? "Sold on Campus Ticket" : "Sold elsewhere"}
              </Switch>
            </div>
          </div>

          <div className={styles.formGroup}>
            <textarea
              placeholder="Optional: Share your experience (max 200 characters)"
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
              maxLength={200}
              className={styles.textarea}
            />
          </div>

          {error && <p className={styles.error}>{error}</p>}
          
          <div className={styles.modalButtons}>
            <button type="submit">Submit Feedback</button>
            <button type="button" onClick={onClose}>Skip</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default TransactionFeedbackModal; 