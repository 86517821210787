import React from 'react';
import axios from 'axios'; 
import styles from '../../styles/components/private-sale-listing.module.css'; // Adjust the path as necessary
import ProfilePgToggleDropdown from "../buttons/custom-dropdowns";
import { useAuth0 } from "@auth0/auth0-react";

// formatDate function defined directly within the same file for simplicity
function formatDate(date) {
  const options = { month: 'short', day: 'numeric' };
  return new Date(date).toLocaleDateString('en-US', options);
}

export const PrivateSaleCard = ({ 
  listing,
  handleEdit, 
  handleMarkAsSold, 
  handleDelete,
  refetchSavedList
}) => {
  const { user } = useAuth0();
  const pricePerTicket = (listing.price / listing.num_tickets);
  const formattedPricePerTicket = pricePerTicket % 1 === 0 ? pricePerTicket.toFixed(0) : pricePerTicket.toFixed(2);  
  const isOwner = user.sub === listing.seller_id; 
  const REACT_APP_FRONTEND_BASE_URL = process.env.REACT_APP_FRONTEND_BASE_URL || 'http://localhost:3000';
  const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080';
  const type = 'listing';

  const handleSeeTixPg = () => {
    const ticketLink = `${REACT_APP_FRONTEND_BASE_URL}/tickets/${listing._id}`;
    window.open(ticketLink, '_blank'); // Open the ticket link in a new tab
  };

  const handleShareProfile = () => {
    const profileLink = `${REACT_APP_FRONTEND_BASE_URL}/profile/${listing.instagram_handle}`;
    navigator.clipboard
      .writeText(profileLink)
      .then(() => alert("Profile link copied to clipboard!"))
      .catch((error) => console.error("Failed to copy profile link:", error));
  };

  const handleCopyTixPg = () => {
    const ticketLink = `${REACT_APP_FRONTEND_BASE_URL}/tickets/${listing._id}`;
    navigator.clipboard
      .writeText(ticketLink)
      .then(() => alert("Ticket link copied to clipboard!"))
      .catch((error) => console.error("Failed to copy ticket link:", error));
  };

  const handleDeleteSaved = async (id) => {
    try {
      const payload = {
        userId: user.sub,
        type: type 
      };
  
      await axios.delete(`${REACT_APP_API_BASE_URL}/api/saved/${id}`, {
        data: payload,
      });

      // update the saved list after unsaving
      if (refetchSavedList) {
        refetchSavedList();
      }

    } catch (error) {
      console.error("Failed to unsave listing:", error);
    }
  }

  return (
    <div key={listing._id} className={styles.eventCard}>
        <div onClick={handleSeeTixPg} style={{ cursor: 'pointer' }} className={styles.artistName}>{listing.event_name}</div>
        <div onClick={handleSeeTixPg} style={{ cursor: 'pointer' }} className={styles.dateLocation}>{formatDate(listing.date)} | {listing.venue}</div>
        <div className={styles.ticketInfo} onClick={handleSeeTixPg}  style={{ cursor: 'pointer' }}>  
          ${listing.price} - {listing.num_tickets} {listing.level} {listing.num_tickets === 1 ? "ticket" : "tickets"}
        </div>
      <div className={styles.buttonFrame}>
        <ProfilePgToggleDropdown label="Options">
        {isOwner ? (
            <>
              <button onClick={() => handleEdit(listing)}>Edit</button>
              {listing.state.toLowerCase() !== "sold" && (
                <button onClick={() => handleMarkAsSold(listing)}>
                  Mark as Sold
                </button>
              )}
              <button onClick={() => handleDelete(listing._id)}>Delete</button>
              <button onClick={handleCopyTixPg}>Share</button>
            </>
          ) : (
            <>
              <button onClick={() => handleDeleteSaved(listing._id)}>Unsave</button>
              <button onClick={handleCopyTixPg}>Share</button>
              <button onClick={() => handleShareProfile(listing.instagram_handle)}>See more from user</button>
            </>
          )}
        </ProfilePgToggleDropdown>
      </div>
    </div>
  );
};
