import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import styles from "../styles/pages/events-page.module.css";
import { PageLayout } from "../components/page-layout";
import { useNavigate } from "react-router-dom";
import { EventCountdown } from "../components/event-cards/eventCountdown";
import GenreModal from "../components/modals/filter-modals/genreModal";
import schoolNames from "../config/schoolNames";
import DropdownButton from "../components/buttons/dropdown-button";

// This is used for the ordering of the page.
const calculateTimeRemaining = (eventDateTime) => {
  const now = Date.now();
  const eventDate = Date.parse(eventDateTime);
  if (isNaN(eventDate)) return Infinity;

  return eventDate - now;
};

const EventsPage = () => {
  const REACT_APP_API_BASE_URL =
    process.env.REACT_APP_API_BASE_URL || "http://localhost:8080";
  const { user } = useAuth0();
  const [displayedArtists, setDisplayedArtists] = useState([]);
  const [artists, setArtists] = useState([]);
  const [fetchedRegionArtists, setFetchedRegionArtists] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const [showGenreModal, setShowGenreModal] = useState(false);
  const [selectedGenre, setSelectedGenre] = useState(null);
  const [region, setRegion] = useState("bay_area"); // Default region
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

  const getRegionFromEmail = (email) => {
    if (!email) return "bay_area";
    const schoolDomain = email.split("@")[1]?.split(".")[0];
    const schoolInfo = schoolNames[schoolDomain];
    return schoolInfo?.region || "bay_area";
  };

  useEffect(() => {
    const initialRegion = getRegionFromEmail(user?.email);
    setRegion(initialRegion);
  }, []);

  useEffect(() => {
    const fetchArtists = async () => {
      try {
        const response = await axios.get(
          `${REACT_APP_API_BASE_URL}/api/events`,
          {
            params: { region },
          }
        );
        const currentDateTime = new Date();
        const fetchedArtists = response.data.filter((event) => {
          const eventDateTime = new Date(event.dateTime);
          return eventDateTime > currentDateTime;
        });

        // Remove duplicates by eventName
        const uniqueEvents = Array.from(
          new Map(
            fetchedArtists.map((artist) => [
              artist.eventName.toLowerCase(),
              artist,
            ])
          ).values()
        );

        setArtists(uniqueEvents);
        setFetchedRegionArtists(artists);
        setDisplayedArtists(fetchedArtists);
      } catch (error) {
        console.error("Error fetching artists:", error);
      }
    };

    fetchArtists();
  }, [region, REACT_APP_API_BASE_URL]);

  const handleSearch = (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);

    // Filter the displayed list based on search term
    const filteredArtists = artists.filter((artist) =>
      artist.eventName.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setDisplayedArtists(filteredArtists);
  };

  const handleArtistPageClick = (artist) => {
    const encodedEventName = encodeURIComponent(artist.eventName);
    const encodedRegion = encodeURIComponent(artist.region);
    navigate(`/events/${encodedRegion}/${encodedEventName}`);
  };

  const handleGenreSelect = (genre) => {
    setSelectedGenre(genre);

    if (!genre) {
      // Reset to show all artists if no genre selected
      setDisplayedArtists(artists);
      return;
    }

    // Filter the original artists array based on the selected genre
    const filteredArtists = artists.filter(
      (event) =>
        event.genre &&
        Array.isArray(event.genre) &&
        event.genre.some((g) => g.toLowerCase().includes(genre.toLowerCase()))
    );

    const sortedFilteredArtists = filteredArtists.sort((a, b) => {
      const timeRemainingA = calculateTimeRemaining(a.dateTime);
      const timeRemainingB = calculateTimeRemaining(b.dateTime);
      return timeRemainingA - timeRemainingB;
    });

    setDisplayedArtists(sortedFilteredArtists);
  };

  const handleRegionSelect = (selectedRegion) => {
    setRegion(selectedRegion);
  };

  const regions = [
    { value: "bay_area", label: "Bay Area" },
    { value: "los_angeles", label: "Los Angeles" },
    { value: "dfw", label: "Dallas" },
  ];

  const currentRegionLabel =
    regions.find((reg) => reg.value === region)?.label || "Select Region";

  const sortedDisplayedArtists = displayedArtists.sort((a, b) => {
    const timeRemainingA = calculateTimeRemaining(a.dateTime);
    const timeRemainingB = calculateTimeRemaining(b.dateTime);
    return timeRemainingA - timeRemainingB;
  });

  return (
    <PageLayout>
      <div className={styles.searchContainer}>
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Search for an artist..."
          className={styles.searchBar}
        />
        {/* Search bar */}
        {/* Only show the artist list if there is a searchTerm and there are matching artists */}
        {searchTerm && displayedArtists.length > 0 && (
          <div className={styles.artistList}>
            {displayedArtists.map((artist) => (
              <div
                key={artist._id}
                className={styles.artistItem}
                onClick={() => handleArtistPageClick(artist)}
              >
                <div></div>
                <img
                  src={artist.imgUrl}
                  alt={artist.eventName}
                  className={styles.artistImage}
                />
                <span>{artist.eventName}</span>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Mobile Header */}
      {isMobile && (
        <>
          <div className={styles.titleContainer}>
            <h3 className={styles.artistsearchpgTitle}>
              {currentRegionLabel} Events
            </h3>
          </div>
          <div className={styles.headerContainer}>
            {/* Region Dropdown */}
            <DropdownButton 
              className={styles.regionDropdown}
              dropdownContentClass={styles.regionDropdownContent}
              dropdownButtonClass={styles.regionDropdownButton}
              label="Region"
            >
              {regions.map((reg) => (
                <button
                  key={reg.value}
                  onClick={() => handleRegionSelect(reg.value)}
                >
                  {reg.label}
                </button>
              ))}
            </DropdownButton>
            <button
              className={styles.genreButton}
              onClick={() => setShowGenreModal(true)}
            >
              Genre
            </button>
          </div>
        </>
      )}

      {/* Desktop Header */}
      {!isMobile && (
        <div className={styles.headerContainer}>
          {/* Region Dropdown */}
          <DropdownButton 
            className={styles.regionDropdown}
            dropdownContentClass={styles.regionDropdownContent}
            dropdownButtonClass={styles.regionDropdownButton}
            label="Region"
          >
            {regions.map((reg) => (
              <button
                key={reg.value}
                onClick={() => handleRegionSelect(reg.value)}
              >
                {reg.label}
              </button>
            ))}
          </DropdownButton>
          <div className={styles.titleContainer}>
            <h3 className={styles.artistsearchpgTitle}>
              {currentRegionLabel} Events
            </h3>
          </div>

          <button
            className={styles.genreButton}
            onClick={() => setShowGenreModal(true)}
          >
            Genre
          </button>
        </div>
      )}

      {/* Artist Tiles */}
      {/* @shiv edit title based on db region */}
      <div className={styles.gridContainer}>
        {sortedDisplayedArtists.map((artist) => (
          <div
            key={artist._id}
            className={styles.eventCard}
            onClick={() => handleArtistPageClick(artist)}
          >
            <EventCountdown dateTime={artist.dateTime} />
            <span>
              <h3>{artist.eventName}</h3>
            </span>
            <img
              src={artist.imgUrl}
              alt={artist.eventName}
              className={styles.artistImage}
            />
          </div>
        ))}
      </div>

      {showGenreModal && (
        <GenreModal
          onClose={() => setShowGenreModal(false)}
          onGenreSelect={handleGenreSelect}
        />
      )}
    </PageLayout>
  );
};

export default EventsPage;
