import React from "react";
import styles from "../../styles/components/datedSoloCards.module.css"; // Ensure you have this CSS module file

export const SaleTicketCard = ({ listing, eventImgUrl }) => {

    // Helper function to format the date as "Month. Day"
  function getMonthDay(dateTimeString) {
    const date = new Date(dateTimeString);
    const options = { month: "short", day: "numeric" };
    const monthDay = date.toLocaleDateString("en-US", options);
    return `${monthDay}.`;
  }

  function getTime(dateTimeString) {
    const date = new Date(dateTimeString);
    let hours = date.getHours();
    let minutes = date.getMinutes().toString().padStart(2, "0");
    const ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // Converts '0' to '12' for midnight times
    return `${hours}:${minutes}${ampm}`;
  }

  const monthDay = getMonthDay(listing?.date);
  const formattedTime = getTime(listing?.date);

  const regions = [
    { value: "bay_area", label: "Bay Area, CA" },
    { value: "los_angeles", label: "Los Angeles, CA" },
    { value: "dfw", label: "Dallas, TX" },
  ];

  return (
    <div className={styles.eventCard}>
      <img className={styles.eventCard} src={eventImgUrl} alt={listing.event_name}/>
      <div className={styles.eventDetails}>
        <div className={styles.eventDateTime}>
          <span className={styles.eventDate}>{monthDay}</span>
          <span className={styles.eventTime}>{formattedTime}</span>
        </div>
        <div className={styles.eventLocation}>
          <div className={styles.eventVenue}>{listing.venue}</div>
          <div className={styles.eventVenue}>
            {regions.find((region) => region.value === listing.region)?.label || ""}
          </div>
          <div className={styles.eventCity}>{listing.num_tickets} {listing.level} tix for ${listing.price}</div>
          {listing.section && <span>"{listing.section}"</span>}
        </div>
      </div>
    </div>
  );
};
