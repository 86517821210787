// chat-page.js
import React, { useState, useEffect, useRef, useCallback } from "react";
import styles from "../styles/pages/chat-page.module.css";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { PageLayoutNoFooter } from "../components/page-layout-no-footer";
import NewChatModal from "../components/modals/newChatModal";
import ChatDisplay from "../components/chatDisplay";
import { useSocketContext } from "../context/socket-provider";
import NewChatIcon from "../styles/img/NewChat.svg";
import { NotificationBadge } from "../components/NotificationBadge";
import { UnreadBadge } from "../components/unreadBadge";

const REACT_APP_FRONTEND_BASE_URL =
    process.env.REACT_APP_FRONTEND_BASE_URL || "http://localhost:3000";
const REACT_APP_API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "http://localhost:8080";

export const ChatPage = () => {
  const { user } = useAuth0();
  const [conversations, setConversations] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [pendingSentChats, setPendingSentChats] = useState(null);
  const [chatInvites, setChatInvites] = useState([]);
  const [showNewChatModal, setShowNewChatModal] = useState(false);
  const [newChatName, setNewChatName] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState("");
  const [invitedUserIG, setInvitedUserIG] = useState("");
  const [showRequests, setShowRequests] = useState(false);
  const [chatHistory, setChatHistory] = useState([]);
  const [chats, setChats] = useState([]);
  const [messages, setMessages] = useState([]);
  const [selectedUserIg, setSelectedUserIg] = useState("");
  const [userId, setUserId] = useState("");
  const [userIg, setUserIg] = useState("");
  const { socket } = useSocketContext();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 540);
  const [showChatDisplay, setShowChatDisplay] = useState(false);
  const [blockedUsers, setBlockedUsers] = useState([]);
  const [totalUnreadNum, setTotalUnreadNum] = useState(0);
  const [unreadStatus, setUnreadStatus] = useState({});

  useEffect(() => {
    const fetchBlockedUsers = async () => {
      try {
        if (user) {
          const response = await axios.get(`${REACT_APP_API_BASE_URL}/api/getUserMetadata/${user.sub}`);
          const blockedUsersData = response.data.metadata.blocked_users;
          setBlockedUsers(blockedUsersData);
          console.log('blockedUsers:', blockedUsersData);
          fetchAcceptedChats(blockedUsers);
        }
      } catch (error) {
        console.error('Error fetching blocked users:', error);
        setError('Failed to fetch blocked users.');
      }
    };
    
    fetchBlockedUsers();
  }, []);  

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 540);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const fetchAcceptedChats = useCallback(() => {
    axios
      .get(
        `${REACT_APP_API_BASE_URL}/api/chats/accepted-chats/${user.instagram_handle}`
      )
      .then((res) => {
        const allChats = res.data;

        const acceptedChats = allChats.filter((chat) => chat.accepted_members_ig.length > 1 && 
          !chat.accepted_members_ig.some((ig) => blockedUsers.includes(ig))
        );
        const sentChats = allChats.filter((chat) => (chat.accepted_members_ig.length === 1 && chat.invited_members_ig.length === 1) && 
          !chat.invited_members_ig.some((ig) => blockedUsers.includes(ig))
        );

        // Set state for accepted conversations
        setConversations(acceptedChats);
        // Set state for pending chats
        setPendingSentChats(sentChats);

        handleSetUnreadCount(acceptedChats, user.sub);

      })
      .catch((error) => {
        console.error("Error fetching accepted chats:", error);
      });
  }, [user.instagram_handle]);


  const handleSetUnreadCount = (chats, userId) => {
    setTotalUnreadNum(0);
    chats.forEach(chat => {
      if (
        (userId === chat.creatorId && chat.unreadByCreator) ||
        (userId !== chat.creatorId && chat.unreadByInvitedUser)
      ) {
        setTotalUnreadNum((prevCount) => prevCount + 1);
        console.log('updating totalUnreadNum:', totalUnreadNum);
      }
    });
  };

  
  const fetchChatInvites = useCallback(() => {
    axios
      .get(
        `${REACT_APP_API_BASE_URL}/api/chats/invited-chats/${user.instagram_handle}`
      )
      .then((res) => setChatInvites(res.data))
      .catch((error) => console.error("Error fetching chat invites:", error));
  }, [user.instagram_handle]);

  useEffect(() => {
    if (blockedUsers) {
      if (!showRequests) {
        fetchAcceptedChats(blockedUsers);
        fetchChatInvites(blockedUsers);
      }
      if (showRequests) {
        fetchAcceptedChats(blockedUsers);
        fetchChatInvites(blockedUsers);
        setConversations([]);
      }
  }
  }, [showRequests]);

  const mergeMessages = (oldMessages, newMessages) => {
    const combined = [
      ...oldMessages,
      ...newMessages.filter(
        (newMsg) => !oldMessages.find((oldMsg) => oldMsg._id === newMsg._id)
      ),
    ];
    return combined.sort(
      (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
    );
  };

  useEffect(() => {
    if (user && selectedChat) {
      // Join the selected chat room
      socket.emit("joinChat", selectedChat._id);

      const handleMessage = (message) => {
        setMessages((prevMessages) => mergeMessages(prevMessages, [message]));
      };
      socket.on("message", handleMessage);

      // Cleanup on component unmount or chat change
      return () => {
        socket.emit("leaveChat", selectedChat._id);
        socket.off("message", handleMessage); //clean up the listener 
      };
    }
  }, [selectedChat]);

  // Handle chat selection and fetching chat history and updating read index
  const handleSelectChat = async (chat) => {
    try {
      setUnreadStatus(prevState => ({
        ...prevState,
        [chat._id]: false, 
      }));
      const response = await axios.get(
        `${REACT_APP_API_BASE_URL}/api/chats/history/${chat._id}`
      );
      const chatHistory = response.data;
      setSelectedChat({
        ...chat,
        messages: chatHistory,
      });

      socket.emit("joinChat", chat._id);
      console.log("opening socket chat from client");

      if (isMobile) {
        setShowChatDisplay(true);
      }

      const readIndexType =
      user && chat.creatorId === user.sub
              ? "lastReadIndexByCreator"
              : "lastReadIndexByInvitedUser";
      const newReadIndex = chatHistory.length -1; 

      await axios.patch(
          `${REACT_APP_API_BASE_URL}/api/chats/${chat._id}/${readIndexType}/${newReadIndex}/update-read-index`
      );

    } catch (error) {
        console.error("Error fetching chat history or updating read index:", error);
    }
  };

  const handleToggleRequests = () => {
    // if (showRequests) {
    //   setSelectedChat(null); 
    // }
    setShowRequests(!showRequests);
  };

  const handleCreateChat = async () => {
    if (!newChatName || !selectedUserIg) {
      setError("Chat name and user to invite are required");
      return;
    }

    const chatData = {
      chatName: newChatName,
      creatorId: userId,
      creatorIg: userIg,
      invited_members_ig: [selectedUserIg],
      historyBackup: Date.now(),
    };

    try {
      const response = await axios.post(
        `${REACT_APP_API_BASE_URL}/api/chats`,
        chatData
      );
      const newChat = response.data;
      // Update UI for the new chat
      setChats([...chats, newChat]);
      setNewChatName("");
      setSelectedUserIg("");
      setError(null);

      // Fetch new chat history
      const chatHistoryResponse = await axios.get(
        `${REACT_APP_API_BASE_URL}/api/chats/history/${newChat._id}`
      );
      const chatHistory = chatHistoryResponse.data;
      setChatHistory(chatHistory);
    } catch (error) {
      console.error("Error creating chat:", error);
      setError("Failed to create chat");
    }
  };

  const handleAcceptInvite = async (chatId) => {
    // Emit socket event for real-time update
    socket.emit("acceptInvite", {
      chatId,
      userId: user.sub,
      userIG: user.instagram_handle,
    });

    try {
      const response = await axios.patch(
        `${REACT_APP_API_BASE_URL}/api/chats/${chatId}/accept`,
        {
          userId: user.sub,
          userIG: user.instagram_handle, // Ensure this matches the backend field
        }
      );

      if (response.status === 200) {
        showConversations(); // Refresh the chat list or perform any other update
      } else {
        console.error("Failed to accept invite. Status:", response.status);
      }
    } catch (error) {
      console.error(
        "Error accepting invite:",
        error.response ? error.response.data : error.message
      );
    }
  };

  const handleRejectInvite = async (chatId) => {
    socket.emit("rejectInvite", { chatId, userIG: user.instagram_handle });

    try {
      const response = await axios.patch(
        `${REACT_APP_API_BASE_URL}/api/chats/${chatId}/reject`,
        {
          userIG: user.instagram_handle,
        }
      );

      if (response.status === 200) {
        fetchChatInvites(); // refresh chat invites
      } else {
        console.error("Failed to reject invite. Status:", response.status);
      }
    } catch (error) {
      console.error(
        "Error rejecting invite:",
        error.response ? error.response.data : error.message
      );
    }
  };

  const handleCloseModal = () => {
    setShowNewChatModal(false);
    fetchAcceptedChats();
  };

  const handleOpenModal = () => {
    setShowNewChatModal(true);
    handleCreateChat();
  };

  const showConversations = () => {
    fetchAcceptedChats();
    setShowRequests(false);
  };

  const handleBackButton = () => {
    setShowChatDisplay(false);
    setSelectedChat(null);
    fetchAcceptedChats();
  };
  
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      year: '2-digit', // 2-digit year
      month: 'numeric', // numeric month
      day: 'numeric', // numeric day
      hour: 'numeric', // numeric hour
      minute: 'numeric', // numeric minute
      hour12: true, // 12-hour clock
    };
    return date.toLocaleString('en-US', options).replace(',', ' @'); // Replace the comma with '@'
  };

  const formatTimestamp = (timestamp) => {
    const now = new Date();
    const date = new Date(timestamp);
    const diffInMs = now - date;
    const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
  
    if (diffInDays < 1) {
      return date.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
    } else if (diffInDays === 1) {
      return "Yesterday";
    } else if (diffInDays < 7) {
      return date.toLocaleDateString([], { weekday: "long" });
    } else {
      return date.toLocaleDateString([], {
        month: "2-digit",
        day: "2-digit",
        year: "2-digit",
      });
    }
  };

  return (
    <PageLayoutNoFooter>
      <div className={styles.chatPageTitleDiv}>
        <h3 className={styles.chatPageTitle}>
          {showRequests ? "Chat Requests" : "Conversations"}
        </h3>
      </div>
      <div className={styles.chatPage}>
      {(!isMobile || (isMobile && !selectedChat)) && (
        <div className={styles.conversationsList}>
          {/* LHS */}

          <div className={styles.chatControls}>
            <button
              onClick={handleToggleRequests}
              className={styles.requestsButton}
            >
              {showRequests ? "Back to Chats" : `View Requests`}
            </button>
            <NotificationBadge showRequests={showRequests} count={showRequests ? totalUnreadNum : chatInvites?.length} />
            <img
              src={NewChatIcon}
              alt="New Chat"
              onClick={() => setShowNewChatModal(true)}
              className={styles.newChatIcon}
            />
          </div>

          {showRequests && (
          <>
            <div className={styles.gridContainer}>
              {chatInvites?.length > 0 ? (
                <>
                  <h2 className={styles.reqHeaders}>My invite requests ({chatInvites?.length}):</h2>
                  {chatInvites?.map((invite) => {
                  const formattedLocalDate = formatDate(invite.historyBackup);
                  return (
                    <div key={invite._id} className={styles.eventCard}>
                      <p>
                        Invite from:{" "}
                        <a
                            href={`${REACT_APP_FRONTEND_BASE_URL}/profile/${invite.creatorIg}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ textDecoration: "none", color: "var(--light-yellow)" }}
                        >
                            @{invite.creatorIg}
                        </a>
                    </p>
                      <p>{invite.chatName}</p>
                      <p>{formattedLocalDate}</p>
                      <p>
                        Msg: {invite.messages.length > 0 
                          ? invite.messages[invite.messages.length - 1].text 
                          : ''}
                      </p>
                      <div className={styles.reqActionButtonWrapper}>
                        <button onClick={() => handleAcceptInvite(invite._id)}
                          className={styles.reqActionButton}
                          >
                          Accept
                        </button>
                        <button onClick={() => handleRejectInvite(invite._id)}
                          className={styles.reqActionButton}>
                          Reject
                        </button>
                      </div>
                      {/* Display success or error messages */}
                      {success && <p style={{ color: "green" }}>{success}</p>}
                      {error && <p style={{ color: "red" }}>{error}</p>}
                    </div>
                  );
                  })}
                </>
              ) : (
                <h2 className={styles.reqHeaders}>No invite requests.</h2> 
              )}
            </div>
              <div className={styles.gridContainer}>
              {pendingSentChats?.length > 0 && (
                  <h2 className={styles.reqHeaders}>My pending sent requests ({pendingSentChats?.length}):</h2>
                )}
                {pendingSentChats?.map((invite) => {
                  // one for now...
                  const invitedPersonIg = invite.invited_members_ig;
                  const formattedLocalDate = formatDate(invite.historyBackup); 

                  return (
                    <div key={invite._id} className={styles.eventCard}>
                          <p>
                            Invited:{" "}
                            <a 
                                href={`${REACT_APP_FRONTEND_BASE_URL}/profile/${invitedPersonIg}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ textDecoration: "none", color: "var(--light-yellow)" }}
                            >
                                @{invitedPersonIg}
                            </a>
                        </p>
                      <p>{invite.chatName}</p>
                      <p>{formattedLocalDate}</p>
                    </div>
                  );
                })}
                </div>
            </>
          )}

          {!showRequests && (
            <>
          <div className={styles.gridContainer}>
            {conversations.map((chat) => {
              const lastMessageObj =
                chat.messages.length > 0
                  ? chat.messages[chat.messages.length - 1]
                  : null;
              const lastMessageSender = lastMessageObj
                ? chat.accepted_members_ig[
                    chat.accepted_members_id.indexOf(lastMessageObj.sender)
                  ]
                : "User";
              const lastMessage = lastMessageObj
                ? `${lastMessageSender}: ${lastMessageObj.text}`
                : "No messages yet";

              return (
                <div
                  key={chat._id}
                  className={styles.chatPreview}
                  onClick={() => handleSelectChat(chat)}
                >
                   <UnreadBadge user={user} chat={chat} unreadStatus={unreadStatus}/>
                  <div className={styles.chatPreviewHeader}>
                    <span className={styles.userName}>
                      {chat.chatName || "Chat"}
                    </span>
                    <span className={styles.timeStamp}>
                      {chat.historyBackup ? formatTimestamp(chat.historyBackup) : ""}
                    </span>
                  </div>
                  <div className={styles.chatPreviewContent}>
                    <p>@{chat.creatorIg || "User"}</p>
                    {/* Display the formatted last message */}
                    <p>{lastMessage}</p>
                  </div>
                </div>
              );
            })}
            {/* New div with motivational text */}
            <div className={styles.motivationalText}>
              <p>Keep Selling Those Tickets!</p>
            </div>
          </div>
          </>
          )}
        </div>
        )}
        
        {/* Chat modal */}
        {showNewChatModal && (
          <NewChatModal
            newChatName={newChatName}
            setNewChatName={setNewChatName}
            invitedUserIG={selectedUserIg}
            setInvitedUserIG={setInvitedUserIG}
            onCreateChat={handleCreateChat}
            acceptedChats={conversations} 
            pendingSentChats={pendingSentChats}
            chatInvites={chatInvites}
            onClose={() => setShowNewChatModal(false)}
          />
        )}
        {/* RHS */}
        {(!isMobile || (isMobile && selectedChat)) && (
          <div className={styles.chatDisplayContainer}>
            <ChatDisplay socket={socket} selectedChat={selectedChat} isMobile={isMobile} handleBackButton={handleBackButton} />
          </div>
        )}
      </div>
    </PageLayoutNoFooter>
  );
};
